
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

class UserDetailForm extends PureComponent {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Form>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input type="email" name="email" id="userEmail" placeholder="Email" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="userPassword">Password</Label>
                <Input type="password" name="password" id="userPassword" placeholder="Password" />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="userAddress">Address</Label>
            <Input type="text" name="address" id="userAddress" placeholder="1234 Main St"/>
          </FormGroup>
          <FormGroup>
            <Label for="userAddress2">Address 2</Label>
            <Input type="text" name="address2" id="userAddress2" placeholder="Apartment, studio, or floor"/>
          </FormGroup>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="userCity">City</Label>
                <Input type="text" name="city" id="userCity"/>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="userState">State</Label>
                <Input type="text" name="state" id="userState"/>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="userZip">Zip</Label>
                <Input type="text" name="zip" id="userZip"/>
              </FormGroup>
            </Col>
          </Row>
          <Button >Update Details</Button>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'user_details_form',
})(UserDetailForm);
