import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';

const WelcomePage = () => (
  <Container className="welcome">
    <Row>
      <Col md={12}>
	    <Card>
	      <CardBody className="text-center">
            <h1 className="font-light font-huge">Welcome</h1>
            <h5 className="font-light">to the service space for the VCU hardware platform</h5>
            <p><img src="/img/product/VCU_Medium.png" style={{maxWidth: '50%', height: 'auto', margin:'30px'}} /></p>
	        <p className="font-light font-medium">Here you'll get access to firmware images, software components, documents and tutorials related to devices based on the VCU hardware platform.</p>
	      </CardBody>
	    </Card>
	  </Col>
    </Row>
  </Container>
);

export default WelcomePage;
