
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from './validate';
import { login } from '../../../redux/actions/userActions';
import queryString from 'query-string';
//import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const renderField = ({ input, placeholder, type, meta: { error } }) => { return (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {error && <span className="form__form-group-error">{error}</span>}
  </div>
)};

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    const hasError = "true" == new URLSearchParams(window.location.search).get("error");
    this.state = {
      showPassword: false,
      showValidation: false,
      username:localStorage.getItem('username'),
      password:localStorage.getItem('password'),
      hasLoginError:hasError,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.renderFormField = this.renderFormField.bind(this);
    this.isLoginDisabled = this.isLoginDisabled.bind(this);
  }

  renderFormField(input) {
    let error = null;
    if (input.meta) {
      if (!this.state.showValidation && !input.meta.touched) {
        input.meta.error = null;
      }
      if (this.state.hasLoginError) {
        input.meta.error = 'Username or password is not correct';
      }
    }
    input.input.value = this.state[input.input.name];
    const field = renderField(input);
    return field;
  }

  handleSubmit(event) {
    //console.log(event);
    if (!this.isLoginDisabled()) {
      const {username,password} = this.state;
      const { dispatch } = this.props;
      localStorage.setItem('username', this.state.username);
      localStorage.setItem('password', this.state.password);
      //dispatch(login(username, password));
    } else {
      event.preventDefault();
      this.setState(prevState => ({ showValidation: true }));
    }
  }

  isLoginDisabled() {
    const {pristine, submitting} = this.props;
    const {username, password} = this.state;
    return username == null || password == null || submitting;
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  handleFieldChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value, showValidation: true, hasLoginError:false});
  }

  render() {
    const {showPassword} = this.state;
    //const { handleSubmit} = this.props;
    //const loginDisabled = this.isLoginDisabled();
    //console.log(this.state);
    return (
      <div>
        <form className="form"  method="POST" action="login" onSubmit={this.handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Username</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="username"
                component={this.renderFormField}
                type="text"
                placeholder="Email address"
                onChange={(evt) => this.handleFieldChange(evt)}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="password"
                component={this.renderFormField}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                onChange={(evt) => this.handleFieldChange(evt)}
              />
              <button
                className={`form__form-group-button${showPassword ? ' active' : ''}`}
                onClick={e => this.showPassword(e)}
                type="button"
              ><EyeIcon />
              </button>
            </div>
            { /** <div className="account__forgot-password">
              <a href="/">Forgot a password?</a>
            </div>
            **/}
          </div>
          <div className="form__form-group">
          {/**
            <div className="form__form-group-field">
              <Field
                name="remember_me"
                component={renderCheckBoxField}
                label="Remember me"
              />
            </div>
          **/}
          </div>
          <button type="submit" className="btn btn-primary account__btn account__btn--small" disabled={this.isLoginDisabled()}>Login</button>
        </form>
      </div>
    );
  }
}

export default reduxForm({ 
  form: 'log_in_form',
  forceUnregisterOnUnmount: true, 
  validate,
})(LogInForm);
