import axios from 'axios';

const backendURL = (process && process.env && process.env.BACKEND_URL) || '';
const URL = `${backendURL}/api/documentation/getall`;

export function getDocumentationFilesApi() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const res = axios.get(`${URL}`);
        resolve(res);
      } catch (e) {
        reject(new Error('Something went wrong'));
      }
    }, 500);
  });
}
