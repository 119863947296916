import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Welcome from '../Welcome/index';
import Documentation from '../Documentation/index';
import Firmware from '../Firmware/index';
import ProfileDetails from '../Profile/index';

const Pages = () => (
  <Switch>
  	<Route path="/pages/Welcome" component={Welcome} />
    <Route path="/pages/Documentation" component={Documentation} />
    <Route path="/pages/Firmware" component={Firmware} />
    <Route path="/pages/Profile" component={ProfileDetails} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/pages" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
