import axios from 'axios';

export function loginApi(username, password) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const URL = `${process.env.BACKEND_URL}/api/account/login`;
      try {
        const user = axios.post(`${URL}`, {
          username,
          password,
        });
        localStorage.setItem('user', JSON.stringify(user));
        resolve(user);
      } catch (e) {
        reject(new Error('Login failed'));
      }
    }, 500);
  });
}

export function logoutApi() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}
