import React , { useState } from 'react';
import { Card, CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import 'moment-timezone';
import { getFirmwareFiles } from '../../../redux/actions/firmwareActions';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import FirmwareCard from './FirmwareCard';

class FirmwareContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor() {
    super()
  }

  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(getFirmwareFiles());
  }

  render (){
    const { firmware } = this.props;
    let sections = firmware?firmware.data:[]
    
    if(sections) {
      return(
          <Col style={{marginLeft: -15}}>
            {sections.map((section, index) => {
                return <FirmwareCard section={section}/>
            })}
          </Col>)
    } else {
      return  (<Col>
                <Card>
                  <CardBody>No files available
                  </CardBody>
                </Card>
              </Col>)
    }
  }

}

export default withRouter(connect(state => ({
  firmware: state.firmwares
}))(FirmwareContainer));



