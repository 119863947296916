import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FirmwareCard from './components/FirmwareCard';
import FirmwareContainer from './components/FirmwareContainer';

const FirmwarePage = () => (
  <Container className="firmware">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Firmware files</h3>
      </Col>
    </Row>
    <Row>
      {/* <FirmwareCard /> */}
      <FirmwareContainer /> 
    </Row>
  </Container>
);

export default FirmwarePage;
