import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const AvatarImage = `${process.env.PUBLIC_URL}/img/avatar.png`;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    
    this.state = {
      collapse: false,
      UserName: localStorage.getItem('username').includes('admin')?'Admin Account':localStorage.getItem('username').includes('development')?'Development Account':'Service Account'
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={AvatarImage} alt="avatar" />
          <p className="topbar__avatar-name font-light">{this.state.UserName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/** <TopbarMenuLink title="Profile" icon="user" path="/pages/Profile" /> */}
            <TopbarMenuLink title="Logout" 		 icon="exit" path="/perform_logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}
