import { getDocumentationFilesApi } from '../../api/documentationApi';

export const GET_DOCUMENTATION_FILES = 'GET_DOCUMENTATION_FILES';

export const getDocumentationFiles = () => {
  return (dispatch) => {
    getDocumentationFilesApi().then((res) => {
      dispatch({
        type: GET_DOCUMENTATION_FILES,
        payload: res.data,
      });
    });
  };
};
