import React , { useState } from 'react';
import { Card, CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import 'moment-timezone';
import { getFirmwareFiles } from '../../../redux/actions/firmwareActions';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 


let initialToggle = 0

class FirmwareCard extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor() {
    super()
    this.state = {
      activeTab: 1,
      setActiveTab: 1
    }
  }

  componentDidMount(){
    const { dispatch } = this.props;
    this.toggle('1'); //start with Tab 1
    dispatch(getFirmwareFiles());
    initialToggle = 0
  }
  
  customConfirmDialog = (filename, folder, downloadHref, type, onClose) => {
    // return(
    //   <div style={{ flexDirection: 'column', borderRadius: 6, alignItems: 'space-between', alignContent: 'space-between', backgroundColor: 'rgb(238, 241, 244)', height: '30vh', width: '50vh'}}>
    //     <Row style={{ alignItems: 'flex-start'}}>
    //       <div>
    //         TITLE
    //       </div>
    //     </Row>
    //     <Row >
    //       <div>
    //         BODY TEXT
    //       </div>
    //     </Row>
    //     <Row style={{ alignItems: 'flex-end'}}>
    //       <div>
    //         BUTTON SECTION
    //       </div>
    //     </Row>
    //   </div>
    // )
    return(
      <Card style={{ display: 'flex', borderRadius: 6, alignItems: 'space-between', alignContent: 'space-between', backgroundColor: 'rgb(238, 241, 244)', height: '30vh', width: '50vh'}}>
                  <CardBody>
                    <div style={{flexDirection: 'column', alignContent: 'flex-end'}}>
                      <div style={{flexDirection: 'row'}} className="card__title">
                        <span>
                          <div style={{paddingBottom: 14}} className="title-text">Delete Firmware</div>
                          <span className="body">Are you sure you want to delete {filename} ?</span>
                        </span>
                      </div>
                      <div style={{position: 'absolute', bottom: 0, paddingBottom: '15px'}}>
                        <Button style={{fontFamily: 'zfprometo'}} color="secondary" onClick={() => {
                          onClose();
                        }}>No</Button>
                        
                        <Button style={{fontFamily: 'zfprometo'}} color="danger" onClick={() => {

                          const requestOptions = {
                            method: 'GET'
                          };

                          //${process.env.PUBLIC_URL} //instead of 8080
                          fetch(`${process.env.PUBLIC_URL}/delete/` + filename + "/" + folder + "/" + type, requestOptions).then((response) => {
                            //console.log("result of delete firmware: " + response.status)
                            this.props.dispatch(getFirmwareFiles());
                          }).catch((error) => {
                            //console.log("error: " + error)
                          });

                          onClose();
                          }}>Yes, delete</Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
    )
  }

  handleDeleteImage = (filename, folder, downloadHref, type) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete this firmware: ' + filename + ' ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'GET'
            };

            //${process.env.PUBLIC_URL} //instead of 8080
            fetch(`${process.env.PUBLIC_URL}/delete/` + filename + "/" + folder + "/" + type, requestOptions).then((response) => {
              console.log("result of delete firmware: " + response.status)
              this.props.dispatch(getFirmwareFiles());
            }).catch((error) => {
              //console.log("error: " + error)
            });
          }
        },
        {
          label: 'No',
          onClick: () => {
            //console.log("dont delete")
          }
        }
      ],
      customUI: ({ onClose }) => this.customConfirmDialog(filename, folder, downloadHref, type, onClose)
    });
  };

  toggle = tab => {
    if(this.state.activeTab !== tab) this.setState({activeTab: tab})
  }

  // handleDeleteImage(filename, folder, downloadHref){
  //   //Alert before delete image
  //   this.submit(filename, folder, downloadHref);
  // }
  //localhost:8080/firmware?fileName=image-propm-1.0.2.tar.gz&imageType=propm
  renderSection (section){
    //const fwFolder = `${process.env.PUBLIC_URL}/firmware/${section.folder}`;
    const fwFolder = `${process.env.PUBLIC_URL}/download?fileName=`//${section.filename}&imageType=${section.folder}`;
    const ProductImage = `${process.env.PUBLIC_URL}/firmware/${section.folder}/product.png`;

    //split "section" in the three categorys of firmwares
    //check which section got no files, so this don't have to be rendered 
    let releaseArray = []
    let devArray = []
    let sdkArray = []
    let binArray = []
    let binDevArray = []
    
    section.files.map((data, index) => {
      if(data && data.type && data.type == "RELEASE"){
        releaseArray.push(data)
      }else if(data && data.type && data.type == "BIN"){
        binArray.push(data)
      }else if(data && data.type && data.type == "DEV"){
        devArray.push(data)
      }else if(data && data.type && data.type == "BINDEV"){
        binDevArray.push(data)
      }else if(data && data.type && data.type == "SDK"){
        sdkArray.push(data)
      }
    })

    //new tab-order is release images, binary release images, development images, binary development images, sdks
    if(initialToggle < 9 && releaseArray && releaseArray.length == 0 && section && section.files.length > 0 && this.state.activeTab!=2 && binArray && binArray.length != 0 ) {
      this.toggle('2'); // TAB 2 == BINARY RELEASE IMAGES 
    }
    if(initialToggle < 9 && releaseArray && releaseArray.length == 0 && section && section.files.length > 0 && this.state.activeTab!=3 && binArray && binArray.length == 0 && devArray && devArray.length != 0 ) {
      this.toggle('3'); // TAB 3 == DEVELOPMENT IMAGES 
    }
    if(initialToggle < 9 && releaseArray && releaseArray.length == 0 && binArray && binArray.length == 0 && devArray && devArray.length == 0 && section && section.files.length > 0 && this.state.activeTab!=4 && binDevArray && binDevArray.length != 0 ) {
      this.toggle('4'); // TAB 4 == BINARY DEVELOPMENT IMAGES 
    }
    if(initialToggle < 9 && releaseArray && releaseArray.length == 0 && binArray && binArray.length == 0 && devArray && devArray.length == 0 && binDevArray && binDevArray.length == 0 && section && section.files.length > 0 && this.state.activeTab!=5 && sdkArray && sdkArray.length != 0 ) {
      this.toggle('5'); // TAB 5 == SDKs
    }
    
    return (<Card>
          <CardBody>
            <div className="card__title">
              <img className="product-img" src={ProductImage} alt="product image" />
              <span>
	              <div className="title-text">{section.title}</div>
	              <span className="subhead">{section.desc}</span>
              </span>
            </div>

            {releaseArray&&releaseArray.length>0||devArray&&devArray.length>0||binArray&&binArray.length>0||sdkArray&&sdkArray.length>0||binDevArray&&binDevArray.length>0?
              
              <div>
            <Nav tabs>
              {releaseArray&&releaseArray.length>0?
                <NavItem>
                <NavLink
                  style={{cursor: 'pointer'}}
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  Release images
                </NavLink>
              </NavItem>:null}
              {binArray&&binArray.length>0?
                <NavItem>
              
                <NavLink
                  style={{cursor: 'pointer'}}
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Binary release images
                </NavLink>
              </NavItem>:null}
              {devArray&&devArray.length>0?
                <NavItem>
                <NavLink
                  style={{cursor: 'pointer'}}
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  Development images
                </NavLink>
              </NavItem>:null}
              {binDevArray&&binDevArray.length>0?
                <NavItem>
              
                <NavLink
                  style={{cursor: 'pointer'}}
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  Binary development images
                </NavLink>
              </NavItem>:null}
              {sdkArray&&sdkArray.length>0?
                <NavItem>
              
                <NavLink
                  style={{cursor: 'pointer'}}
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  SDKs
                </NavLink>
              </NavItem>:null}
            </Nav>

            <TabContent style={{paddingTop: 20}} activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>

                  <Col sm="12">
                  {releaseArray.map((data, index) => {

                    const downloadHref = `${fwFolder}${data.filename}&imageType=${section.folder}&category=RELEASE`;
                    const dateToFormat = new Date(data.updatedAt);
                    return(
                        <Row >
                          <Col >
                            {index==0?<h5>File</h5>:null}
                            <p><a href={downloadHref}><span className="card__title-icon lnr lnr-download"> </span>{data.filename}</a></p>
                          </Col>
                          <Col style={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <Row>
                              <Col >
                                <div style={{float: 'right'}}>
                                  {index==0?<h5>Date</h5>:null}
                                  <p style={{minWidth: '125px'}} className="font-light"><Moment format="L LT" date={dateToFormat}/></p>
                                </div>
                              </Col>
                                {
                                  localStorage.getItem('username').includes('admin')?
                                  <Col>
                                  <div style={{float: 'right'}}>
                                  {index==0?<h5 style={{color: 'white'}}>d</h5>:null}
                                  <p onClick={() => this.handleDeleteImage(data.filename, section.folder, downloadHref, data.type)} style={{ color: 'red', cursor: 'pointer', textAlign: 'center', maxWidth: '60px'}}><span className="card__title-icon lnr lnr-trash" style={{ color: 'red' }}> </span>Delete</p>
                                  </div>
                                  </Col>
                                  :null
                                }
                            </Row>
                          </Col>
                        </Row>
                      )

                  })
                  }

                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                <Col sm="12">

                  {binArray.map((data, index) => {

                    const downloadHref = `${fwFolder}${data.filename}&imageType=${section.folder}&category=BIN`;
                    const dateToFormat = new Date(data.updatedAt);
                    return(
                        <Row >
                          <Col >
                            {index==0?<h5>File</h5>:null}
                            <p><a href={downloadHref}><span className="card__title-icon lnr lnr-download"> </span>{data.filename}</a></p>
                          </Col>
                          <Col style={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <Row>
                              <Col >
                                <div style={{float: 'right'}}>
                                  {index==0?<h5>Date</h5>:null}
                                  <p style={{minWidth: '125px'}} className="font-light"><Moment format="L LT" date={dateToFormat}/></p>
                                </div>
                              </Col>
                                {
                                  localStorage.getItem('username').includes('admin')?
                                  <Col>
                                  <div style={{float: 'right'}}>
                                  {index==0?<h5 style={{color: 'white'}}>d</h5>:null}
                                  <p onClick={() => this.handleDeleteImage(data.filename, section.folder, downloadHref, data.type)} style={{ color: 'red', cursor: 'pointer', textAlign: 'center', maxWidth: '60px'}}><span className="card__title-icon lnr lnr-trash" style={{ color: 'red' }}> </span>Delete</p>
                                  </div>
                                  </Col>
                                  :null
                                }
                            </Row>
                          </Col>
                        </Row>
                      )
                  })
                  }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                <Col sm="12">
                  {
                    devArray.map((data, index) => {

                    const downloadHref = `${fwFolder}${data.filename}&imageType=${section.folder}&category=DEV`;
                    const dateToFormat = new Date(data.updatedAt);
                      return(
                        <Row >
                          <Col >
                            {index==0?<h5>File</h5>:null}
                            <p><a href={downloadHref}><span className="card__title-icon lnr lnr-download"> </span>{data.filename}</a></p>
                          </Col>
                          <Col style={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <Row>
                              <Col >
                                <div style={{float: 'right'}}>
                                  {index==0?<h5>Date</h5>:null}
                                  <p style={{minWidth: '125px'}} className="font-light"><Moment format="L LT" date={dateToFormat}/></p>
                                </div>
                              </Col>
                                {
                                  localStorage.getItem('username').includes('admin')?
                                  <Col>
                                  <div style={{float: 'right'}}>
                                  {index==0?<h5 style={{color: 'white'}}>d</h5>:null}
                                  <p onClick={() => this.handleDeleteImage(data.filename, section.folder, downloadHref, data.type)} style={{ color: 'red', cursor: 'pointer', textAlign: 'center', maxWidth: '60px'}}><span className="card__title-icon lnr lnr-trash" style={{ color: 'red' }}> </span>Delete</p>
                                  </div>
                                  </Col>
                                  :null
                                }
                            </Row>
                          </Col>
                        </Row>
                      )
                  })
                  }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                <Col sm="12">

                  {binDevArray.map((data, index) => {

                    const downloadHref = `${fwFolder}${data.filename}&imageType=${section.folder}&category=BINDEV`;
                    const dateToFormat = new Date(data.updatedAt);
                    return(
                        <Row >
                          <Col >
                            {index==0?<h5>File</h5>:null}
                            <p><a href={downloadHref}><span className="card__title-icon lnr lnr-download"> </span>{data.filename}</a></p>
                          </Col>
                          <Col style={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <Row>
                              <Col >
                                <div style={{float: 'right'}}>
                                  {index==0?<h5>Date</h5>:null}
                                  <p style={{minWidth: '125px'}} className="font-light"><Moment format="L LT" date={dateToFormat}/></p>
                                </div>
                              </Col>
                                {
                                  localStorage.getItem('username').includes('admin')?
                                  <Col>
                                  <div style={{float: 'right'}}>
                                  {index==0?<h5 style={{color: 'white'}}>d</h5>:null}
                                  <p onClick={() => this.handleDeleteImage(data.filename, section.folder, downloadHref, data.type)} style={{ color: 'red', cursor: 'pointer', textAlign: 'center', maxWidth: '60px'}}><span className="card__title-icon lnr lnr-trash" style={{ color: 'red' }}> </span>Delete</p>
                                  </div>
                                  </Col>
                                  :null
                                }
                            </Row>
                          </Col>
                        </Row>
                      )
                  })
                  }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                <Col sm="12">

                  {sdkArray.map((data, index) => {

                    const downloadHref = `${fwFolder}${data.filename}&imageType=${section.folder}&category=SDK`;
                    const dateToFormat = new Date(data.updatedAt);
                    return(
                        <Row >
                          <Col >
                            {index==0?<h5>File</h5>:null}
                            <p><a href={downloadHref}><span className="card__title-icon lnr lnr-download"> </span>{data.filename}</a></p>
                          </Col>
                          <Col style={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <Row>
                              <Col >
                                <div style={{float: 'right'}}>
                                  {index==0?<h5>Date</h5>:null}
                                  <p style={{minWidth: '125px'}} className="font-light"><Moment format="L LT" date={dateToFormat}/></p>
                                </div>
                              </Col>
                                {
                                  localStorage.getItem('username').includes('admin')?
                                  <Col>
                                  <div style={{float: 'right'}}>
                                  {index==0?<h5 style={{color: 'white'}}>d</h5>:null}
                                  <p onClick={() => this.handleDeleteImage(data.filename, section.folder, downloadHref, data.type)} style={{ color: 'red', cursor: 'pointer', textAlign: 'center', maxWidth: '60px'}}><span className="card__title-icon lnr lnr-trash" style={{ color: 'red' }}> </span>Delete</p>
                                  </div>
                                  </Col>
                                  :null
                                }
                            </Row>
                          </Col>
                        </Row>
                      )
                  })
                  }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            </div>
            :
              <div>{(section.title.toLowerCase()==="base" || section.title.toLowerCase()==="standard" || section.title.toLowerCase()==="procv" || section.title.toLowerCase()==="detagtive tag finder") ? <div>Note: This content has moved to <a href='https://service-space.zf-v2x.com' target='_blank'>ZF Connectivity Suite | ProConnect Service Space</a>.</div> : <div>No files available</div>}</div>
            }

          </CardBody>
        </Card>)
  }

  render (){
    const { firmware, sectionTitle } = this.props;
    let sections = firmware?firmware.data:[]

    if(sections && sections.length>0) {
      return(
          <Col>
            {sections.map((section, index) => {
              if(sectionTitle && section.title == sectionTitle){
                if(index == 0) {
                  initialToggle++
                }
                return this.renderSection(section)
              }else{
                return null
              }
            })}
          </Col>)
    } else {
      return  (<Col>
                <Card>
                  <CardBody>No files available
                  </CardBody>
                </Card>
              </Col>)
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (state != null) {
    return {
      firmware: state.firmwares,
      sectionTitle: ownProps.section.title
    };
  }
  return {
    firmware: null,
    sectionTitle: ownProps.section.title
  };
}

export default withRouter(connect(mapStateToProps)(FirmwareCard));




// {section.files.map((data, index) => {
//   const downloadHref = `${fwFolder}${data.filename}&imageType=${section.folder}`;
//   const dateToFormat = new Date(data.updatedAt);
//   if(data && data.type){
//     //check if type is available
//     //type order is always the same, first RELEASE 2.DEV 3. SDKs
//     if(data.type == "DEV" && headingImages != "Development images"){
//       headingImages = "Development images"
//       insertHeading = true;
//     }else if(data.type == "SDK" && headingImages != "SDKs"){
//       headingImages = "SDKs"
//       insertHeading = true;
//     }else{
//       if(index > 0){
//         insertHeading = false;
//       }
//     }
//   }
//   return (
//     <div>
//     {insertHeading?<Row><Col md="4"><h5>{headingImages}</h5></Col></Row>:null}
//     <Row>
//     <Col  md="4" xs="3">
//       <p><a href={downloadHref}><span className="card__title-icon lnr lnr-download"> </span>{data.filename}</a></p>
//     </Col>
//     <Col >
//       <p className="font-light"><Moment format="L LT" date={dateToFormat}/></p>
//     </Col>
//     {
//       localStorage.getItem('username').includes('admin')?
//       <Col >
//       <p onClick={() => this.handleDeleteImage(data.filename, section.folder, downloadHref, data.type)} style={{ color: 'red', cursor: 'pointer' }}><span className="card__title-icon lnr lnr-cross-circle" style={{ color: 'red' }}> </span>Delete image</p>
//       </Col>
//       :null
//     }
    
//   </Row>
//   </div>
//   )
// })}