import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getDocumentationFiles } from '../../redux/actions/documentationActions';

const docsFolder = `${process.env.PUBLIC_URL}/download?fileName=`

class DocumentationContainer extends React.Component {
	static propTypes = {
	  dispatch: PropTypes.func.isRequired
	};
  
	constructor() {
	  super()
	}
  
	componentDidMount(){
	  const { dispatch } = this.props;
	  dispatch(getDocumentationFiles());
	}

	createDocumentationCard(section) {
		const downloadHref = `${docsFolder}${section.filename}&category=DOC`;
		return(
			<Card>
				<CardBody>
					<div className="card__title">
					<span className='card__title-icon lnr lnr-graduation-hat' />
					<span className="title-text">{section.title}</span>
					</div>
					<div>
						<table className="summary__info">
							<tbody>
								<tr><th>Description:</th><td>{section.desc}</td></tr>
								<tr><th>Version:</th><td>{section.version}</td></tr>
								<tr><th>Download:</th><td><a href={downloadHref} target="_blank" rel="noopener noreferrer">{section.filename}</a></td></tr>
							</tbody>
						</table>
					</div>
				</CardBody>
			</Card>
		);
	}
  
	render (){
	  const { documentation } = this.props;
	  let sections = documentation?documentation.data:[]
	  
	  if (sections) {
		sections.sort(function(a, b) {
		  if (a.title < b.title) {
			return -1;
		  }
		  if (a.title > b.title) {
			return 1;
		  }
		  return 0;
		});
	  }
	  
	  if(sections) {
		return(
			<Container className="documentation">
				<Row><Col md={12}><h3 className="page-title">Documentation</h3></Col></Row>
				<Row>
      				<Col md={12}>
					  {sections.map((section, index) => {
							return this.createDocumentationCard(section)
						})}
					</Col>
				</Row>
				<Row><Col md={12}><h3 className="page-title">Tutorials</h3></Col></Row>
				<Row>
					<Col md={12}>
					<Card>
					<CardBody>
						<div className="card__title">
						<span className='card__title-icon lnr lnr-rocket' />
						<div className="title-text">Sample Applications</div>
						</div>
						<div>
							<table className="summary__info">
								<tbody>
									<tr><th>Description:</th><td>Basic applications demonstrating core features of the VCU software stack for major platforms (Java, C++, Python).</td></tr>
									<tr><th>Link:</th><td><a href="https://gitlab.com/vcu-public" target="_blank">Application Git Link</a></td></tr>
								</tbody>
							</table>
						</div>
					</CardBody>
					</Card>
				</Col>
				</Row>
			</Container>)
	  } else {
		return  (
			<Container className="documentation">
				<Row><Col md={12}><h3 className="page-title">Tutorials</h3></Col></Row>
				<Row>
					<Col md={12}>
					<Card>
					<CardBody>
						<div className="card__title">
						<span className='card__title-icon lnr lnr-rocket' />
						<div className="title-text">Sample Applications</div>
						</div>
						<div>
							<table className="summary__info">
								<tbody>
									<tr><th>Description:</th><td>Basic applications demonstrating core features of the VCU software stack for major platforms (Java, C++, Python).</td></tr>
									<tr><th>Link:</th><td><a href="https://gitlab.com/vcu-public" target="_blank">Application Git Link</a></td></tr>
								</tbody>
							</table>
						</div>
					</CardBody>
					</Card>
				</Col>
				</Row> 
			</Container>)
	  }
	}
  
  }
  
  export default withRouter(connect(state => ({
	documentation: state.documentations
  }))(DocumentationContainer));
  

const DocumentationPage = () => (
  <Container className="documentation">
    <Row><Col md={12}><h3 className="page-title">Documentation</h3></Col></Row>
    <Row>
      <Col md={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <span className='card__title-icon lnr lnr-graduation-hat' />
	          <span className="title-text">VCU Administrator Guide</span>
	        </div>
	        <div>
		        <table className="summary__info">
	        		<tbody>
	        			<tr><th>Description:</th><td>Document for system and application engineers describing the technical configuration and customization of VCU devices.</td></tr>
	        			<tr><th>Version:</th><td>0.6</td></tr>
	        			<tr><th>Download:</th><td><a href={docsFolder +'/VCU-Administrator-Guide.pdf'} target="_blank">VCU-Administrator-Guide.pdf</a></td></tr>
	        		</tbody>
	        	</table>
			</div>
	      </CardBody>
	    </Card>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <span className='card__title-icon lnr lnr-graduation-hat' />
	          <span className="title-text">VCU Developer Guide</span>
	        </div>
	        <div>
	        	<table className="summary__info">
	        		<tbody>
	        			<tr><th>Description:</th><td>Document for software developers that what to create applications running on VCU devices.</td></tr>
	        			<tr><th>Version:</th><td>0.2</td></tr>
	        			<tr><th>Download:</th><td><a href={docsFolder +'/VCU-Developer-Guide.pdf'} target="_blank">VCU-Developer-Guide.pdf</a></td></tr>
	        		</tbody>
	        	</table>
			</div>
	      </CardBody>
	    </Card>
		<Card>
	      <CardBody>
	        <div className="card__title">
	          <span className='card__title-icon lnr lnr-graduation-hat' />
	          <span className="title-text">ODC Configuration Guide</span>
	        </div>
	        <div>
	        	<table className="summary__info">
	        		<tbody>
	        			<tr><th>Description:</th><td>Document for system and application engineers describing the configuration options and technical setup of the ODC application.</td></tr>
	        			<tr><th>Version:</th><td>0.3</td></tr>
	        			<tr><th>Download:</th><td><a href={docsFolder +'/ODC-Configuration-Guide.pdf'} target="_blank">ODC-Configuration-Guide.pdf</a></td></tr>
	        		</tbody>
	        	</table>
			</div>
	      </CardBody>
	    </Card>
	   </Col>
	  </Row>
	  <Row><Col md={12}><h3 className="page-title">Tutorials</h3></Col></Row>
	  <Row>
	  	<Col md={12}>
	    <Card>
	      <CardBody>
	        <div className="card__title">
	          <span className='card__title-icon lnr lnr-rocket' />
	          <div className="title-text">Sample Applications</div>
	        </div>
	        <div>
	        	<table className="summary__info">
	        		<tbody>
	        			<tr><th>Description:</th><td>Basic applications demonstrating core features of the VCU software stack for major platforms (Java, C++, Python).</td></tr>
	        			<tr><th>Link:</th><td><a href="https://gitlab.com/vcu-public" target="_blank">Application Git Link</a></td></tr>
	        		</tbody>
	        	</table>
			</div>
	      </CardBody>
	    </Card>
	  </Col>
    </Row>
  </Container>
);

//export default DocumentationPage;
