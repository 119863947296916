import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import {
  sidebarReducer, themeReducer, firmwareReducer, documentationReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  firmwares: firmwareReducer,
  documentations: documentationReducer,
});

const store = createStore(
  reducer,
  applyMiddleware(thunk),
);

export default store;
