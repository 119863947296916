import { createBrowserHistory } from 'history';
import { loginApi, logoutApi } from '../../api/userApi';

export const history = createBrowserHistory();

export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
};

export const login = (username, password) => {
  return (dispatch) => {
    dispatch({ type: userConstants.LOGIN_REQUEST, payload: username });
    loginApi(username, password).then((user) => {
      dispatch({ type: userConstants.LOGIN_SUCCESS, payload: user });
      history.push('/pages/Welcome');
    }, (error) => {
      dispatch({ type: userConstants.LOGIN_FAILURE, payload: error });
    });
  };
};

export const logout = () => {
  logoutApi();
  return { type: userConstants.LOGOUT };
};
