import { GET_DOCUMENTATION_FILES } from '../actions/documentationActions';

const initialState = { data: '' };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTATION_FILES:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
