import { GET_FIRMWARE_FILES } from '../actions/firmwareActions';

const initialState = { data: '' };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FIRMWARE_FILES:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
