import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
            <SidebarLink title="Welcome" route="/pages/Welcome" icon="home" onClick={this.hideSidebar} />
            <SidebarLink title="Documentation" route="/pages/Documentation" icon="text-align-justify" onClick={this.hideSidebar} />
            <SidebarLink title="Firmware" route="/pages/Firmware" icon="enter-down" onClick={this.hideSidebar} />
        </ul>
        <div className="sidebar__footer">
            <div className="nowrap font-small">Version 1.0.4-SNAPSHOT</div>
            <div className="nowrap font-small font-light">&copy; 2020, ZF Friedrichshafen AG</div>
        </div>
      </div>
    );
  }
}

export default SidebarContent;
