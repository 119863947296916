import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserDetailForm from './components/UserDetailForm';
const AvatarImage = `${process.env.PUBLIC_URL}/img/avatar.png`;

const ProfileDetails = () => (
  <Container className="profile">
    <Row>
      <Col md={12}>
        <h3 className="page-title">User Details</h3>
      </Col>
    </Row>
    <Row>
      <img className="topbar__avatar-img profile" src={AvatarImage} alt="avatar" />
    </Row>
    <Row>
      <Col>
        <UserDetailForm onSubmit/>
      </Col>
    </Row>
  </Container>
);

export default ProfileDetails;
