import { getFirmwareFilesApi } from '../../api/firmwareApi';

export const GET_FIRMWARE_FILES = 'GET_FIRMWARE_FILES';

export const getFirmwareFiles = () => {
  return (dispatch) => {
    getFirmwareFilesApi().then((res) => {
      dispatch({
        type: GET_FIRMWARE_FILES,
        payload: res.data,
      });
    });
  };
};
